<template>
  <div
    class="card col-sm-6 col-md-4 col-lg-3 col-xxl-2 m-1 mx-auto p-2 eh-434 text-center"
  >
    <a
      class="product-link eh-65"
      :href="productUrl"
      target="_blank"
    >
      <img
        class="product-image-card m-auto eh-100"
        :alt="parsedProductName"
        :src="productThumb"
        loading="lazy"
      >
    </a>
    <div class="card-body d-flex flex-grow-1">
      <div class="row">
        <div class="col-sm-12 overflow-auto eh-80 d-flex">
          <a
            class="product-link fw-bold m-auto"
            :href="productUrl"
            target="_blank"
          >{{ parsedProductName }}</a>
        </div>
        <div class="col-sm-12">
          <small class="text-muted">
            <template v-if="hasPrice">
              <span
                class="m-1 fs-6">{{ formattedPrice }}</span>
              <span>&nbsp;·&nbsp;</span>
            </template>
            <span class="m-1">{{ formattedAvailability }}</span>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SearchResultMinimal from './search-result-minimal.vue';

  export default
  {
    extends: SearchResultMinimal
  }
</script>

<style type="text/css" lang="sass" scoped>
  .card
    border: none
    background-color: whitesmoke
    cursor: pointer
    border-radius: 0

    transition: 0.35s ease all

    &:hover
      box-shadow: 0 0px 3px 0px #b4b4b4
      z-index: 1

  a.product-link
    font-size: 0.9rem

</style>
